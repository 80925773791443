

const humanLikes = [
    {key: 1, text: 'D&D'},
    {key: 1, text: 'Hiking'},
    {key: 2, text: 'Fantasy Novels'},
    {key: 3, text: 'Pizza'},
    {key: 4, text: 'Funky Shirts'},
    {key: 5, text: 'Funky Music'},
]
const digitalLikes = [
    {key: 1, text: 'Problem Solving'},
    {key: 1, text: 'Learning New Technology'},
    {key: 2, text: '"No Merge Conflicts"'},
    {key: 3, text: 'Making Plans'},
    {key: 4, text: 'Fixing THAT Bug'},
    {key: 5, text: 'Also Funky Music'},
]

export {humanLikes, digitalLikes}
